import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
// import { toast } from "react-toastify";
import { notify } from '../../components/toast/Toast'
import "react-toastify/dist/ReactToastify.css";
import { CONTACT } from "../../axios/AllBaseUrl";
import { ApiConnector } from "../../axios/apiClient";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 focus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
  description = "",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [inputValue, setInputValue] = useState({
    "email": "",
    "full_name": "",
    "subject": "",
    "msg": ""
  });

  const handelInputchange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  }
  // console.log(inputValue);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (inputValue.email.length === 0 ||
        inputValue.full_name.length === 0 ||
        inputValue.subject.length === 0 ||
        inputValue.msg.length === 0) {
        notify(2, "Please fill all mandatory the fields");
      }
      else {
        let res = await ApiConnector("post", CONTACT, {
          "email": inputValue.email,
          "full_name": inputValue.full_name,
          "subject": inputValue.subject,
          "msg": inputValue.msg
        })

        if (res.data.ok === false) {
          notify(2, res.data.msg);
        }
        if (res.data.ok === true) {
          notify(1, "Successfully submited !");
          setInputValue({
            "email": "",
            "full_name": "",
            "subject": "",
            "msg": ""
          })
        }

      }
    }
    catch (err) {
      notify(2, "Something went wrong");
    }
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod}>
              <Input type="email" name="email"
                placeholder="Your Email Address"
                onChange={handelInputchange}
                value={inputValue.email}
              />
              <Input type="text" name="full_name"
                placeholder="Full Name"
                onChange={handelInputchange}
                value={inputValue.full_name}
              />
              <Input type="text" name="subject"
                placeholder="Subject"
                onChange={handelInputchange}
                value={inputValue.subject}
              />
              <Textarea name="msg"
                placeholder="Your Message Here"
                onChange={handelInputchange}
                value={inputValue.msg}
              />
              <SubmitButton type="submit"
                onClick={handleSubmit}
              >
                {submitButtonText}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
