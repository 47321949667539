import axios from "axios";

export const ApiConnector = async (
  method,
  url,
  data = {},
  headers = {}
) => {
  let token;
  let gettoken= localStorage.getItem("token");

  if (gettoken !== undefined && gettoken !== "undefined") {
    token = JSON.parse(gettoken);
  }
  let res = await axios({
    method: `${method}`,
    url: `${url}`,
    data: data,
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });

  return res;
};
