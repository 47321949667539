import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { Link } from "react-router-dom";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32 bg-white rounded-lg p-2`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const LinkTxt = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
	${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
	svg {
		${tw`w-5 h-5`}
	}
`;
const CompanyLogo = tw.div`w-full inline-flex items-center`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
	return (
		<Container>
			<Content>
				<Row>
					<LogoContainer>
						<LogoImg src={logo} />
						<LogoText>Digital</LogoText>
					</LogoContainer>
					<LinksContainer>
						<LinkTxt>
							<Link to="/">Home</Link>
						</LinkTxt>
						<LinkTxt>
							<Link to="/aboutUs">About Us</Link>
						</LinkTxt>
						<LinkTxt>
							<Link to="/contactUs">Contact Us</Link>
						</LinkTxt>
					</LinksContainer>
					<SocialLinksContainer>
						<SocialLink>
							<Link
								to="https://www.facebook.com/evidyadigital"
								target="_blank"
							>
								<FacebookIcon />
							</Link>
						</SocialLink>
						<SocialLink>
							<Link
								to="https://twitter.com/evidyadigital"
								target="_blank"
							>
								<TwitterIcon />
							</Link>
						</SocialLink>
						<SocialLink>
							<Link
								to="https://www.instagram.com/evidyadigital"
								target="_blank"
							>
								<InstagramIcon />
							</Link>
						</SocialLink>
						<SocialLink to="https://www.youtube.com/channel/UCMEsw4Po8iFVCit7Wt4qfpA">
							<Link
								to="https://www.youtube.com/channel/UCMEsw4Po8iFVCit7Wt4qfpA"
								target="_blank"
							>
								<YoutubeIcon />
							</Link>
						</SocialLink>
					</SocialLinksContainer>
					<CopyrightText>
						&copy; Copyright 2023, Adhyan Digital Pvt. Ltd. All
						Rights Reserved.
					</CopyrightText>
				</Row>
			</Content>
		</Container>
	);
};
