import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
	SectionHeading,
	Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
// import Founder from "images/suvendu-sir.jpg";
import Dip from "images/dip.png";
import Dipayan from "images/dipayan.png";
import Gitabitan from "images/GitabitanPati.png";
import Riju from "images/riju.png";
import Ipsita from "images/ipsita.png";
import Masud from "images/masud.png";
import Soumyadip from "images/soumyadip.png";
import Jayanta from "images/jayanta.png";
import Koushik from "images/koushik.jpg"
import Sutapa from "images/sutapa-ghosh.jpg";
import Kamaliya from "images/kamaliya.png";
import Piyali from "images/Piyali.png";
import Swagupta from "images/SwaguptaBiswas.png";
import Rajesh from "images/RajeshBala.png";
import Nabarup from "images/Nabarup.jpg";
import Priyanka from "images/Priyanka.png";
import Ankita from "images/Ankita_Saha.jpeg";


const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
	${(props) =>
		css`
			background-image: url("${props.imageSrc}");
		`}
	${tw`w-64 h-64 bg-cover bg-center rounded-full`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

// const CardLinks = styled.div`
// 	${tw`mt-6 flex`}
// 	.link {
// 		${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
// 		.icon {
// 			${tw`fill-current w-6 h-6`}
// 		}
// 	}
// `;

export default ({
	heading = "Meet The Wizards",
	subheading = "Our Team",
	// description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
	cards = [
		{
			imageSrc: Dip,
			position: "Software Developer",
			name: "Dip Kumar Kapat",
		},
		{
			imageSrc: Dipayan,
			position: "IT Admin",
			name: "Dipayan Saha",
		},
		{
			imageSrc: Gitabitan,
			position: "Software Developer",
			name: "Gitabitan Pati",
		},
		{
			imageSrc: Riju,
			position: "Software Developer",
			name: "Riju Biswas",
		},
		{
			imageSrc: Ipsita,
			position: "Software Developer",
			name: "Ipsita Bhowmick",
		},
		{
			imageSrc: Masud,
			position: "Software Developer",
			name: "Masud Biswas",
		},
		{
			imageSrc: Soumyadip,
			position: "Software Developer",
			name: "Soumyadip Panja",
		},
		{
			imageSrc: Jayanta,
			position: "Accounts Manager",
			name: "Jayanta Kundu",
		},
		{
			imageSrc: Koushik,
			position: "E-Content Administrator",
			name: "Koushik Basak",
		},
		{
			imageSrc: Sutapa,
			position: "Video Editor",
			name: "Sutapa Ghosh",
		},
		{
			imageSrc: Kamaliya,
			position: "Video Editor",
			name: "Kamaliya Bhattachriya",
		},
    	{
			imageSrc: Piyali,
			position: "Hr & BDO",
			name: "Piyali Chowdhury",
		},
		{
			imageSrc: Swagupta,
			position: "FACULTY",
			name: "Swagupta Biswas",
		},
		{
			imageSrc: Rajesh,
			position: "Software Developer",
			name: "Rajesh Bala",
		},
		{
			imageSrc: Nabarup,
			position: "Software Developer",
			name: "Nabarup Paul",
		},
		{
			imageSrc: Priyanka,
			position: "BDE",
			name: "Priyanka Mukherjee",
		},
		{
			imageSrc: Ankita,
			position: "FACULTY",
			name: "Ankita Saha",
		},
	],
}) => {
	return (
		<Container>
			<ContentWithPaddingXl>
				<HeadingContainer>
					{subheading && <Subheading>{subheading}</Subheading>}
					{heading && <Heading>{heading}</Heading>}
					{/* {description && <Description>{description}</Description>} */}
				</HeadingContainer>
				<Cards>
					{cards.map((card, index) => (
						<Card key={index}>
							<CardImage imageSrc={card.imageSrc} />
							<CardContent>
								<span className="position">
									{card.position}
								</span>
								<span className="name">{card.name}</span>
								{/* <CardLinks>
									{card.links.map((link, linkIndex) => (
										<a
											key={linkIndex}
											className="link"
											href={link.url}
										>
											<link.icon className="icon" />
										</a>
									))}
								</CardLinks> */}
							</CardContent>
						</Card>
					))}
				</Cards>
			</ContentWithPaddingXl>
		</Container>
	);
};
