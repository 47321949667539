import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import promoVideo from "../../images/eVidya_promo_ad.mp4";

import Header, {
	NavLink,
	NavLinks,
	PrimaryLink,
	LogoLink,
	NavToggle,
	DesktopNavLinks,
} from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
	${tw`pt-8 max-w-none`}
	${DesktopNavLinks} ${NavLink}, ${LogoLink} {
		${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
	}
	${NavToggle}.closed {
		${tw`text-gray-100 hover:text-primary-500`}
	}
`;
const Container = styled.div`
	${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
	background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
	${tw`text-3xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-none mb-12`}
	span {
		${tw`inline-block mt-2`}
	}
`;

const SlantedBackground = styled.span`
	${tw`relative text-primary-500 px-4 -mx-4 py-2`}
	&::before {
		content: "";
		${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
	}
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-primary-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
	padding-bottom: 56.25% !important;
	padding-top: 0px !important;
	${tw`rounded`}
	iframe {
		${tw`rounded bg-black shadow-xl`}
	}
`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

export default () => {
	const navLinks = [
		<NavLinks key={1}>
			<NavLink to="/">Home</NavLink>
			<NavLink to="/aboutUs">About Us</NavLink>
			{/* <NavLink href="#">
        Blog
      </NavLink> */}
			<NavLink to="/contactUs">Contact Us</NavLink>
			{/* <NavLink href="#">
        Pricing
      </NavLink> */}
		</NavLinks>,
		// <NavLinks key={2}>
		//   <PrimaryLink href="/#">
		//     Hire Us
		//   </PrimaryLink>
		// </NavLinks>
	];

	return (
		<Container>
			<OpacityOverlay />
			<HeroContainer>
				<StyledHeader>
					<LinksContainer>
						<Link to="/">Home</Link>
						<Link to="/aboutUs">About Us</Link>
						<Link to="/contactUs">Contact Us</Link>
					</LinksContainer>
				</StyledHeader>
				<TwoColumn>
					<LeftColumn>
						<Notification>
							We are present across India.
						</Notification>
						<Heading>
							<span>Connect with the best</span>
							<br />
							<SlantedBackground>
								Educational Platform.
							</SlantedBackground>
						</Heading>
					</LeftColumn>
					<RightColumn>
						<StyledResponsiveVideoEmbed
							url={promoVideo}
							background="transparent"
						/>
					</RightColumn>
				</TwoColumn>
			</HeroContainer>
		</Container>
	);
};
