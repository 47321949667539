import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { ApiConnector } from "../../axios/apiClient";
import { SEND_PHONE_NO } from "../../axios/AllBaseUrl";
import { toast } from "react-toastify";
import {notify} from '../../components/toast/Toast'
import "react-toastify/dist/ReactToastify.css";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Our back office team are always at your service to help you at any point of time. So be assured that you are in safe hands for after sales services.",
  submitButtonText = "Contact Us",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  let [phoneNo, setPhoneNo] = useState("");
  let getPhoneNo = (e) => {
    setPhoneNo(e.target.value);
  };
  let sendPhoneNo = async (e) => {
    e.preventDefault();
    try {
      if (phoneNo?.trim().length !== 0) {
        let res = await ApiConnector("post", SEND_PHONE_NO, {
          phone_no: phoneNo,
        });

        if (res.data.ok) {
          setPhoneNo("");
          notify(1, "We will inform as soon as possible !");
        }
        if (!res.data.ok) {
          notify(2, res.data.msg);
        }
      }
      if (phoneNo?.trim().length === 0) {
        notify(2, "Phone no can't be blank !");
      }
    } catch (error) {
      notify(2, "Something went wrong !");
    }
  };
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Form action={formAction} method={formMethod}>
              <Input
                type="phone"
                name="phone"
                value={phoneNo}
                placeholder="Your Phone No."
                onChange={getPhoneNo}
              />
              <SubmitButton onClick={sendPhoneNo}>
                {submitButtonText}
              </SubmitButton>
              
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
