import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
// import ContactDetails from "components/cards/ThreeColContactDetails.js";
// import { Container } from "components/misc/Layouts";

const Address = tw.span`leading-relaxed`;
const AddressHead = tw.span`text-lg font-medium`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
const Container = tw.div`w-full relative`;
const TwoColumn = tw.div`grid grid-cols-1 md:grid-cols-2 justify-start items-start max-w-screen-xl mx-auto gap-8 mb-8`;
const LocationMap = tw.div`flex justify-start items-center border rounded-lg`;
const ContactDetails = tw.div`flex flex-col justify-start items-start`;
const Caption = tw.div`w-full flex justify-center items-center text-5xl font-bold pb-8`;

export default () => {
	return (
		<AnimationRevealPage>
			<Header />
			<ContactUsForm />
			<Caption>
				<>Our Office</>
			</Caption>
			<Container>
				<TwoColumn>
					<LocationMap>						
						<iframe title="Address" 
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14737.068333565197!2d88.430917!3d22.569112!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275adfab28d3f%3A0xa6158f942cea5e42!2sS.D.F.!5e0!3m2!1sen!2sin!4v1707124865853!5m2!1sen!2sin" allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
							width={"100%"}
							height={"400px"}></iframe>
					</LocationMap>
					<ContactDetails>
						<Address>
							<AddressHead>Adhyan Digital Pvt. Ltd.</AddressHead>
							<AddressLine>SDF Building</AddressLine>
							<AddressLine>Module - 328, 2nd Floor,</AddressLine>
							<AddressLine>
							GP Block, Sector V, Bidhannagar,
							</AddressLine>
							<AddressLine>
								Kolkata - 700 091 West Bengal, India
							</AddressLine>
						</Address>
						<Email>Email: contact@adhyandigital.com</Email>
						<Phone>Phone: +91 9830034401/9674737281</Phone>
					</ContactDetails>
				</TwoColumn>
			</Container>
			<Footer />
		</AnimationRevealPage>
	);
};
