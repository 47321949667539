/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from "react";

export default ({ url, background = "black", className = "video" }) => {
	return (
		<div
			className={className}
			style={{
				position: "relative",
				background: background,
				paddingBottom: "56.25%" /* 16:9 */,
				paddingTop: 25,
				height: 0,
				borderRadius: "10px",
			}}
		>
			{/* <iframe
				title="Embeded Video"
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
				}}
				src={url + `?autoplay=1&loop=1`}
				allow="accelerometer; autoplay; picture-in-picture; fullscreen"
				allowfullscreen
			/> */}
			<video
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
				}}
				controls={true}
				autoPlay={true}
				loop={true}
				preload="auto"
			>
				<source src={url} type="video/mp4" />
			</video>
		</div>
	);
};
